<div [class]="'alert alert--' + type">
   <button
      class="alert__close"
      (click)="off.emit(false)"
      type="button"
      role="button"
      aria-label="fechar alerta"
   >
      x
   </button>

   <span [class.grid]="!!icon">
      <i class="material-icons" *ngIf="!!icon">{{ icon }}</i>
      <p class="alert__mensage">{{ text }}</p>
   </span>
</div>

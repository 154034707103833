import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
   selector: 'multi-input',
   templateUrl: './free-inputs.component.html',
   styleUrls: ['./../inputs/inputs.component.scss', './free-inputs.component.scss'],
})
export class FreeInputsComponent {
   @Input() input_type: 'input' | 'select' | 'input-currency' | 'select-search'
   @Input() label: string = ''
   @Input() icon: string = ''
   @Input() type: string
   @Input() placeholder: string = ''
   @Input() data: any
   @Input() defaultValue: number | string
   @Output() valueEvent = new EventEmitter<any>()
   public options = {
      align: 'right',
      prefix: 'R$ ',
      allowNegative: false,
      allowZero: true,
      decimal: ',',
      precision: 2,
      suffix: '',
      thousands: '.',
   }
   constructor() {}
}

<div [ngSwitch]="type">
   <div *ngSwitchCase="'bottom'">
      <span class="arrow bottom" [class.active]="active"><span></span><span></span></span>
   </div>
   <div *ngSwitchCase="'top'">
      <span class="arrow top" [class.active]="active"><span></span><span></span></span>
   </div>
   <div *ngSwitchCase="'left'">
      <span class="arrow left" [class.active]="active"><span></span><span></span></span>
   </div>
   <div *ngSwitchCase="'right'">
      <span class="arrow right" [class.active]="active"><span></span><span></span></span>
   </div>

   <div *ngSwitchCase="'plus'">
      <div class="plusminus" [class.active]="active"></div>
   </div>
</div>

<ng-container *ngIf="input_type === 'input'">
   <div class="input-wrapper">
      <span class="input-wrapper__label-icon">
         <label [attr.for]="label">{{ label }}</label>
      </span>
      <input
         #input
         [type]="type"
         [attr.aria-placeholder]="placeholder"
         [placeholder]="placeholder"
         (keypress)="valueEvent.emit($event)"
      />
   </div>
</ng-container>

<ng-container *ngIf="input_type === 'input-currency'">
   <div class="input-wrapper">
      <span class="input-wrapper__label-icon">
         <label [attr.for]="label">{{ label }}</label>
      </span>
      <input
         #input
         type="text"
         maxlength="19"
         [attr.aria-placeholder]="placeholder"
         [placeholder]="placeholder"
         currencyMask
         [options]="options"
         (keypress)="valueEvent.emit($event)"
      />
   </div>
</ng-container>

<ng-container *ngIf="input_type === 'select'">
   <div>
      <label for="food">{{ label }}</label>
      <mat-form-field appearance="outline">
         <mat-select
            [placeholder]="placeholder"
            disableOptionCentering
            [value]="defaultValue"
            (selectionChange)="valueEvent.emit($event)"
         >
            <mat-option *ngFor="let item of data" [value]="item.value">
               {{ item.viewValue }}
            </mat-option>
         </mat-select>
      </mat-form-field>
   </div>
</ng-container>

<ng-container *ngIf="input_type === 'select-search'">
   <div>
      <label for="food">{{ label }}</label>
      <mat-form-field appearance="outline">
         <mat-select
            [placeholder]="placeholder"
            disableOptionCentering
            [value]="defaultValue"
            (selectionChange)="valueEvent.emit($event)"
         >
            <mat-option *ngFor="let item of data" [value]="item.nameFilter">
               {{ item.nameFilter }}
            </mat-option>
         </mat-select>
      </mat-form-field>
   </div>
</ng-container>
